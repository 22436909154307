// Windows 8 mobile Responsive utility
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
	var msViewportStyle = document.createElement('style')
	msViewportStyle.appendChild(
		document.createTextNode(
			'@-ms-viewport{width:auto!important}'
		)
	)
	document.querySelector('head').appendChild(msViewportStyle)
}

jQuery(document).ready(function () {
  // LazyLoading
	jQuery("[data-src]").on("inview", function() {
		var $this = $(this);
		$this.attr("src", $this.attr("data-src"));
		// Remove it from the set of matching elements in order to avoid that the handler gets re-executed
		$this.removeAttr("data-src");
	});
	jQuery("[data-srcset]").on("inview", function() {
		var $this = $(this);
		$this.attr("srcset", $this.attr("data-srcset"));
		// Remove it from the set of matching elements in order to avoid that the handler gets re-executed
		$this.removeAttr("data-srcset");
	});
  // show/hide footer content
  jQuery(".footer__title").click(function(){
    jQuery(this).toggleClass("is-active");
    jQuery(this).parent().find(".footer__content").slideToggle();
  });
	//sliders
	jQuery(".hp-banner").slick({
		arrows: true,
		dots: true,
		prevArrow: '<span class="slick-prev icon-vlevo"></span>',
		nextArrow: '<span class="slick-next icon-vpravo"></span>'
	});
	var slideCount = $('.paging-info');
  var slickSlide = $('#detail-images');
  slickSlide.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    $(this).parents().find('.paging-info').html('<span class="slideCountItem">' + i + '</span> ' + '/' + ' <span class="slideCountAll">' + slick.slideCount + '</span>');
  });
	slickSlide.slick({
		infinite: false,
		arrows: true,
		dots: false,
		prevArrow: '<span class="slick-prev icon-vlevo"></span>',
		nextArrow: '<span class="slick-next icon-vpravo"></span>'
	});
	jQuery("#detail-variants").slick({
		arrows: true,
		dots: false,
		prevArrow: '<span class="slick-prev icon-vlevo"></span>',
		nextArrow: '<span class="slick-next icon-vpravo"></span>',
		slidesToShow: 4,
  	slidesToScroll: 1,
		responsive: [
	    {
	      breakpoint: 1224,
	      settings: {
	        slidesToShow: 3
	      }
	    },
	    {
	      breakpoint: 992,
	      settings: {
	        slidesToShow: 4
	      }
	    },
	    {
	      breakpoint: 768,
	      settings: {
	        slidesToShow: 3
	      }
	    }
  	]
	});
	jQuery(".products-slider").slick({
		arrows: true,
		dots: false,
		prevArrow: '<span class="slick-prev icon-vlevo"></span>',
		nextArrow: '<span class="slick-next icon-vpravo"></span>',
		slidesToShow: 4,
  	slidesToScroll: 1,
		responsive: [
	    {
	      breakpoint: 768,
	      settings: {
	        slidesToShow: 3
	      }
	    },
			{
	      breakpoint: 576,
	      settings: {
	        slidesToShow: 2
	      }
	    }
  	]
	});
	//openers
	jQuery(".lang__opener").click(function(){
		jQuery(this).parent().toggleClass("is-active");
	});
	jQuery(".icon-menu, .nav-mask").click(function(){
		jQuery(".nav, .nav-mask").toggleClass("is-active");
		jQuery("html, body").toggleClass("nav-is-active");
	});
	jQuery(".filters__opener--filters").click(function(){
		jQuery(this).toggleClass("is-active");
		jQuery(this).parents(".container").find(".filters__box").slideToggle(300);
	});
	jQuery(".filters__opener--sections").click(function(){
		jQuery(this).toggleClass("is-active");
		jQuery(this).parent().find(".filters__sections").slideToggle(300);
	});
	jQuery(".filters__bottom .icon-nahoru").click(function(){
		jQuery(".filters__opener--filters").toggleClass("is-active");
		jQuery(this).parents().find(".filters__box").slideToggle(300);
	});
	jQuery(".s-acc__opener").click(function(){
		jQuery(this).toggleClass("is-active");
		jQuery(this).parent().find(".s-acc__hidden").slideToggle(300);
	});
	if ($(window).width() < 992){
		jQuery(".with-subnav > a").click(function(event){
			event.preventDefault();
			jQuery(this).parent().find("ul").slideToggle();
		});
	}
	jQuery(".detail__box h2").click(function(){
		jQuery(this).toggleClass("is-active");
		jQuery(this).parent().find(".detail__content").slideToggle();
		jQuery('.products-slider').slick('refresh');
	});
	jQuery(".s-cart__opener").click(function(){
		jQuery(this).toggleClass("is-active");
		jQuery(this).parent().find(".s-cart__hidden").slideToggle();
	});

	//price slider

	/*var priceSliderMain = document.getElementById('price-slider');

  if (priceSliderMain) {

    var language = $('body').attr('class');
    if (language == "czk")
    var curr = "KÄŤ";
    else
    var curr = "â‚¬";

    var price = $('#eprice').val().split('-');

    var min_val = parseFloat(price[0]);
    var max_val = parseFloat(price[1]);

    if(min_val==max_val){
    max_val=max_val+1;
    }

    noUiSlider.create(priceSliderMain, {
      start: [ 0, max_val ],
      format: wNumb({
          decimals: 0,
          thousand: ' '
      }),
    	connect: true,
    	direction: 'ltr',
      range: {
        'min': min_val,
        'max': max_val
      }
    });*/
    /*priceSliderMain.noUiSlider.on('update', function ( values, handle, unencoded, isTap, positions ) {
    	[document.getElementById('price-slider-lvalue'), document.getElementById('price-slider-uvalue')][handle].innerHTML = values[handle];
    });*/

    /*priceSliderMain.noUiSlider.on('slide', function( values, handle ){
      (handle ? limitFieldMax : limitFieldMin).innerHTML = parseInt(values[handle]);

      $("#eprice").val(values);
      var valus = $("#eprice").val();
      var vals = valus.split(',');
      $("#eprice").val(vals[0]+"-"+vals[1]);
      $("#nextpage").val(0);
      $('.paging_next.basic').css('display', 'none');
      $("#cpage").val('0');
      ajax_list();
    });


  }
	var priceSliderMain = document.getElementById('price-slider-mobile');

  if (priceSliderMain) {

    var language = $('body').attr('class');
    if (language == "czk")
    var curr = "KÄŤ";
    else
    var curr = "â‚¬";

    var price = $('#eprice').val().split('-');

    var min_val = parseFloat(price[0]);
    var max_val = parseFloat(price[1]);

    if(min_val==max_val){
    max_val=max_val+1;
    }

    noUiSlider.create(priceSliderMain, {
      start: [ 0, max_val ],
      format: wNumb({
          decimals: 0,
          thousand: ' '
      }),
    	connect: true,
    	direction: 'ltr',
      range: {
        'min': min_val,
        'max': max_val
      }
    });*/
    /*priceSliderMain.noUiSlider.on('update', function ( values, handle, unencoded, isTap, positions ) {
    	[document.getElementById('price-slider-lvalue-mobile'), document.getElementById('price-slider-uvalue-mobile')][handle].innerHTML = values[handle];
    });*/

    /*priceSliderMain.noUiSlider.on('slide', function( values, handle ){
      (handle ? limitFieldMax : limitFieldMin).innerHTML = parseInt(values[handle]);

      $("#eprice").val(values);
      var valus = $("#eprice").val();
      var vals = valus.split(',');
      $("#eprice").val(vals[0]+"-"+vals[1]);
      $("#nextpage").val(0);
      $('.paging_next.basic').css('display', 'none');
      $("#cpage").val('0');
      ajax_list();
    });

  }*/

	//anchors
	jQuery(".up").click(function(e) {
    e.preventDefault();
    var aid = jQuery(this).attr("href");
    jQuery('html,body').animate({scrollTop: jQuery(aid).offset().top},'slow');
	});
	jQuery(".detail__anchors a").click(function(e) {
    e.preventDefault();
    var aid = jQuery(this).attr("href");
    jQuery('html,body').animate({scrollTop: jQuery(aid).offset().top},'slow');
	});
	jQuery(".search-r__anchors a").click(function(e) {
    e.preventDefault();
    var aid = jQuery(this).attr("href");
    jQuery('html,body').animate({scrollTop: jQuery(aid).offset().top},'slow');
	});
	//selects
	jQuery("select").selectric({
		arrowButtonMarkup: '<b class="button"></b>'
	});
	//products counter
	jQuery("body").on("click", ".count > span", function() {
    var $button = jQuery(this);
    var oldValue = $button.parent().find("input").val();
    if ($button.attr("class") == "icon-plus") {
      var newVal = parseFloat(oldValue) + 1;
    }
    else {
      // Don't allow decrementing below zero
      if (oldValue > 1) {
        var newVal = parseFloat(oldValue) - 1;
      }
      else {
        newVal = 1;
      }
    }
    $button.parent().find("input").val(newVal);
  });
	jQuery("body").on("click", ".s-cart__counter > span", function() {
    var $button = jQuery(this);
    var oldValue = $button.parent().find("input").val();
    if ($button.attr("class") == "icon-plus") {
      var newVal = parseFloat(oldValue) + 1;
    }
    else {
      // Don't allow decrementing below zero
      if (oldValue > 1) {
        var newVal = parseFloat(oldValue) - 1;
      }
      else {
        newVal = 1;
      }
    }
    $button.parent().find("input").val(newVal);
  });
	jQuery(".comparison__wrap").niceScroll({
		cursorcolor: "#386bbf",
		cursorwidth: '20px',
		background: "#f3f3f3",
		railvalign: "top",
		autohidemode: false
	});
	// delete product
	jQuery(".s-cart__delete").click(function(){
		jQuery(this).parent().parent().fadeOut("normal", function() {
      jQuery(this).remove();
    });
	});
	// (wysiwyg editor)
	jQuery(".entry-content iframe").each(function () {
    jQuery(this).addClass("embed-responsive-item").wrap("<div class=\"embed-responsive embed-responsive-16by9\"></div>");
    jQuery(this).removeAttr("width");
    jQuery(this).removeAttr("height");
  });
  // (wysiwyg editor)
  jQuery(".entry-content table").each(function () {
    jQuery(this).addClass("table table-responsive-sm");
  });
	jQuery(".panel .icon-hledat, .panel__mobile .icon-hledat").click(function(event){
		event.preventDefault();
    jQuery(".search-box, .search-layer").toggleClass("is-active");
		jQuery(".search-box input").focus();
  });
	jQuery(".search-box .icon-vypnout").click(function(event){
		event.preventDefault();
    jQuery(".search-box, .search-layer").removeClass("is-active");
  });
	jQuery('[data-toggle="tooltip"]').tooltip()
});
jQuery(document).mouseup(function(e)
{
    var container = jQuery(".search-box");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0)
    {
        jQuery(".search-box, .search-layer").removeClass("is-active");
    }
});
